<template>
  <div id="requisition">
    <el-dialog
      :title="requisitionFormTitle"
      width="1200px"
      :visible.sync="requisitionDialogVisible"
      :close-on-click-modal="false"
      @close="requisitionDialogClose"
    >
      <el-form
        ref="requisitionFormRef"
        :model="requisitionForm"
        :rules="requisitionFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="申请部门" prop="dept">
              <el-select
                v-model="requisitionForm.dept"
                placeholder="请选择申请部门"
                clearable
                :disabled="requisitionFormTitle !== '新增采购申请单'
                  && requisitionFormTitle !== '修改采购申请单'
                  && requisitionFormTitle !== '采购申请单详情'"
                @change="deptChange"
              >
                <el-option
                  v-for="item in ['生产设备部', '质量部', '采购部', '仓储部', '销售部']"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="编号" prop="requisitionNo">
              <el-input
                v-model="requisitionForm.requisitionNo"
                placeholder="请输入编号"
                clearable
                :disabled="requisitionFormTitle !== '新增采购申请单'
                  && requisitionFormTitle !== '修改采购申请单'
                  && requisitionFormTitle !== '采购申请单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="申请日期" prop="applyDate">
              <el-date-picker
                v-model="requisitionForm.applyDate"
                placeholder="请选择申请日期"
                value-format="yyyy-MM-dd"
                :disabled="requisitionFormTitle !== '新增采购申请单'
                  && requisitionFormTitle !== '修改采购申请单'
                  && requisitionFormTitle !== '采购申请单详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <vxe-toolbar
            v-if="requisitionFormTitle === '新增采购申请单'
              || requisitionFormTitle === '修改采购申请单'"
          >
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="detailList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column
              field="materialName"
              title="名称"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="factory"
              title="厂家"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="spec"
              title="规格描述"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="quantity"
              title="数量"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="estimatedPrice"
              title="预计价格"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="deliveryDate"
              title="期望到货时间"
              :edit-render="{name: '$input', props: {type: 'date', clearable: true}}"
            />
            <vxe-table-column
              field="remarks"
              title="备注"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              v-if="requisitionFormTitle === '新增采购申请单'
                || requisitionFormTitle === '修改采购申请单'"
              title="操作"
              width="100"
            >
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="批准结果" prop="approvalResult">
              <el-radio-group
                v-model="requisitionForm.approvalResult"
                :disabled="requisitionFormTitle !== '批准采购申请单'
                  && requisitionFormTitle !== '采购申请单详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="确认结果" prop="confirmorResult">
              <el-radio-group
                v-model="requisitionForm.confirmorResult"
                :disabled="requisitionFormTitle !== '确认采购申请单'
                  && requisitionFormTitle !== '采购申请单详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="requisitionDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="requisitionFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="编号">
        <el-input v-model="searchForm.requisitionNo" placeholder="请输入编号" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['PURCHASE_APPLY_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="requisitionPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
    >
      <el-table-column prop="requisitionNo" label="编号" />
      <el-table-column label="申请日期">
        <template slot-scope="scope">
          <span v-if="scope.row.applyDate">{{ scope.row.applyDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="dept" label="申请部门" />
      <el-table-column prop="applicant" label="申请人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.applicationDate">{{ scope.row.applicationDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="approver" label="批准人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.approvalDate">{{ scope.row.approvalDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="批准结果">
        <template slot-scope="scope">
          <span v-if="scope.row.approvalResult === 1">通过</span>
          <span v-if="scope.row.approvalResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column prop="confirmor" label="确认人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.confirmationDate">{{ scope.row.confirmationDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="确认结果">
        <template slot-scope="scope">
          <span v-if="scope.row.confirmorResult === 1">通过</span>
          <span v-if="scope.row.confirmorResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['PURCHASE_APPLY_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['PURCHASE_APPLY_UPDATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['PURCHASE_APPLY_APPROVAL']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleApproval(scope.$index, scope.row)"
          >
            批准
          </el-button>
          <el-button
            v-if="checkPermission(['PURCHASE_APPLY_CONFIRM']) && scope.row.status === 2"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleConfirm(scope.$index, scope.row)"
          >
            确认
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="requisitionPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addRequisition,
  deleteRequisition,
  updateRequisition,
  selectRequisitionInfo,
  selectRequisitionList,
  approvalRequisition,
  confirmRequisition
} from '@/api/purchase/requisition'
import moment from 'moment'

export default {
  data () {
    return {
      requisitionDialogVisible: false,
      requisitionFormTitle: '',
      requisitionForm: {
        id: '',
        requisitionNo: '',
        applyDate: '',
        dept: '',
        approvalResult: '',
        confirmorResult: '',
        status: '',
        type: 1,
        statusFlag: 1,
        requisitionDetailJson: '',
        taskId: ''
      },
      requisitionFormRules: {
        requisitionNo: [{ required: true, message: '编号不能为空', trigger: ['blur', 'change']}]
      },
      requisitionPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        requisitionNo: '',
        type: 1
      },
      detailList: []
    }
  },
  created () {
    selectRequisitionList(this.searchForm).then(res => {
      this.requisitionPage = res
    })
  },
  methods: {
    requisitionDialogClose () {
      this.$refs.requisitionFormRef.resetFields()
      this.detailList = []
    },
    requisitionFormSubmit () {
      if (this.requisitionFormTitle === '采购申请单详情') {
        this.requisitionDialogVisible = false
        return
      }
      this.$refs.requisitionFormRef.validate(async valid => {
        if (valid) {
          if (this.requisitionFormTitle === '新增采购申请单') {
            this.requisitionForm.id = ''
            this.requisitionForm.status = 1
            this.requisitionForm.requisitionDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await addRequisition(this.requisitionForm)
          } else if (this.requisitionFormTitle === '修改采购申请单') {
            this.requisitionForm.requisitionDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await updateRequisition(this.requisitionForm)
          } else if (this.requisitionFormTitle === '批准采购申请单') {
            this.requisitionForm.status = 2
            await approvalRequisition(this.requisitionForm)
          } else if (this.requisitionFormTitle === '确认采购申请单') {
            this.requisitionForm.status = 3
            await confirmRequisition(this.requisitionForm)
          }
          this.requisitionPage = await selectRequisitionList(this.searchForm)
          this.requisitionDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.requisitionFormTitle = '新增采购申请单'
      this.requisitionDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteRequisition(row.id)
        if (this.requisitionPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.requisitionPage = await selectRequisitionList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.requisitionFormTitle = '修改采购申请单'
      this.requisitionDialogVisible = true
      this.selectRequisitionInfoById(row.id)
    },
    handleApproval (index, row) {
      this.requisitionFormTitle = '批准采购申请单'
      this.requisitionDialogVisible = true
      this.selectRequisitionInfoById(row.id)
    },
    handleConfirm (index, row) {
      this.requisitionFormTitle = '确认采购申请单'
      this.requisitionDialogVisible = true
      this.selectRequisitionInfoById(row.id)
    },
    handleInfo (index, row) {
      this.requisitionFormTitle = '采购申请单详情'
      this.requisitionDialogVisible = true
      this.selectRequisitionInfoById(row.id)
    },
    selectRequisitionInfoById (id) {
      selectRequisitionInfo(id).then(res => {
        this.requisitionForm.id = res.id
        this.requisitionForm.requisitionNo = res.requisitionNo
        this.requisitionForm.applyDate = res.applyDate
        this.requisitionForm.dept = res.dept
        this.requisitionForm.approvalResult = res.approvalResult
        this.requisitionForm.confirmorResult = res.confirmorResult
        this.requisitionForm.status = res.status
        this.requisitionForm.type = res.type
        this.requisitionForm.statusFlag = res.statusFlag
        this.requisitionForm.taskId = res.taskId
        this.detailList = res.detailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectRequisitionList(this.searchForm).then(res => {
        this.requisitionPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectRequisitionList(this.searchForm).then(res => {
        this.requisitionPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectRequisitionList(this.searchForm).then(res => {
        this.requisitionPage = res
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['PURCHASE_APPLY_APPROVAL']) && row.status === 1) {
        return 'review'
      } else if (this.checkPermission(['PURCHASE_APPLY_CONFIRM']) && row.status === 2) {
        return 'review'
      }
      return ''
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    },
    deptChange (value) {
      if (value === '生产设备部') {
        this.requisitionForm.requisitionNo = 'CS' + moment().format('YYYYMMDD') + '-SCSB-'
      } else if (value === '质量部') {
        this.requisitionForm.requisitionNo = 'CS' + moment().format('YYYYMMDD') + '-ZL-'
      } else if (value === '采购部') {
        this.requisitionForm.requisitionNo = 'CS' + moment().format('YYYYMMDD') + '-CG-'
      } else if (value === '仓储部') {
        this.requisitionForm.requisitionNo = 'CS' + moment().format('YYYYMMDD') + '-CC-'
      } else if (value === '销售部') {
        this.requisitionForm.requisitionNo = 'CS' + moment().format('YYYYMMDD') + '-XS-'
      } else {
        this.requisitionForm.requisitionNo = ''
      }
    }
  }
}
</script>

<style>
</style>
